import { socketInstance, storageService } from '@app/common';
import { authService } from '@app/stores/mainStore.store';
import { baseApiUrl, createError, isJsonString } from '@app/utils';
import { makeAutoObservable } from 'mobx';

class _socketService {
  socket;
  status = '';
  isLoading = false;
  isConnected = false;

  setIsConnected = (isConnected) => (this.isConnected = isConnected);
  setIsLoading = (isLoading) => (this.isLoading = isLoading);
  setStatus = (status) => (this.status = status);

  init = async (__init = true) => {
    try {
      await this.socket.initSocket(__init);
    } catch (e) {
      console.error(e, ' init socket error');
    }
  };

  on = (method, callback) => {
    try {
      this.socket.on(method, callback);
    } catch (e) {
      throw createError(typeof e === 'object' ? JSON.stringify(e) : e);
    }
  };

  emit = async ({ method, params = {} }) => {
    this.setIsLoading(true);
    try {
      // console.debug(method, '||method emit||');
      // console.debug(JSON.stringify(params, null, 1), '||params emit||');
      const response = await this.socket.emit({ method, params });
      // console.debug(response, '||response emit||');
      return response;
    } catch (e) {
      // console.debug(e, 'error socketservice');
      const serializeError = (e) => (isJsonString(e) ? JSON.parse(e) : e);
      const error = serializeError(e);
      // console.debug(error);
      if (error) {
        if (typeof error === 'string' && error.includes('JsonWebTokenError')) {
          throw createError('Вы не авторизованы', 'auth');
        } else {
          throw createError(
            error.message ?? 'Что-то пошло не так: ' + e,
            error.name ?? 'default',
          );
        }
      }
      // throw createError(typeof e === "object" ? JSON.stringify(e) : e);
    } finally {
      this.setIsLoading(false);
    }
  };
  bulk = async ({ method, params = {} }) => {
    this.setIsLoading(true);
    try {
      // console.debug(method, '||method bulk||');
      const response = await this.socket.bulk({ method, params });
      // console.debug(response, '||response bulk||');
      return response;
    } catch (e) {
      console.error(e, 'error bulk socketService');
      const serializeError = (e) => (isJsonString(e) ? JSON.parse(e) : e);
      const error = serializeError(e);
      // console.debug(error);
      if (error) {
        throw createError(
          error.message ?? 'Что-то пошло не так: ' + e,
          error.name ?? 'default',
        );
      }
      // throw createError(typeof e === "object" ? JSON.stringify(e) : e);
    } finally {
      this.setIsLoading(false);
    }
  };

  removeAllListeners = (event) => {
    if (this.socket) {
      this.socket.removeAllListeners(event);
    }
  };

  changeSocketUrl = (url) => {
    this.destroy();
    this.socket = new socketInstance({
      ...this.socket,
      url,
    });
  };
  destroy = () => {
    this.socket.destroy();
    this.socket.removeAllListeners();
  };

  constructor({ url }) {
    this.socket = new socketInstance({
      url,
      onConnect: async () => {
        this.setIsConnected(true);
        this.setIsLoading(false);
      },
      onDisconnect: () => {
        this.setIsConnected(false);
      },
      onReconnecting: async () => {
        this.setIsLoading(true);
        this.setIsConnected(false);
      },
      onReconnect: async () => {
        this.setIsConnected(true);
        this.setIsLoading(false);
        await authService.accountLoginWithToken();
      },
    });
    makeAutoObservable(this);
  }
}

const socketService = new _socketService({
  //FIXME переделать на динамическое подключение
  // url: baseAPIUrl(),
  url: storageService.getItem('MS-API') || baseApiUrl(),
});
export { socketService };
