import { createError } from '@app/utils';
import io from 'socket.io-client';

class _socketInstance {
  socket;
  _serverUrl;

  constructor({ onConnect, onDisconnect, onReconnecting, onReconnect, url }) {
    this.onConnect = onConnect;
    this.onDisconnect = onDisconnect;
    this.onReconnecting = onReconnecting;
    this.onReconnect = onReconnect;
    this._serverUrl = url;
  }

  initSocket = async (__init) => {
    return new Promise((resolve) => {
      if (this.socket?.connect) return;
      if (__init) {
        console.debug(__init, 'should init socket instance');
        console.debug(this._serverUrl, 'serverUrl init socket instance');
        if (!this._serverUrl) {
          throw Error('Please provide socket url');
        }
        console.debug(this._serverUrl, 'try to connect by provided url');
        if (!this.socket || !this.socket?.connected) {
          console.debug('not connected socket instance');
          this.socket = io(this._serverUrl, {
            transports: ['websocket'],
            reconnect: 20000,
          });
          console.debug(this.socket, 'created Socket');
          this.socket.on('connect', (e) => {
            this.socket.removeAllListeners('common-event');

            this.onConnect && this.onConnect(e);
            console.debug('SOCKET: ||connected||');
            resolve();
          });
          this.socket.on('connection', (socket) => {
            console.debug(socket, 'SOCKET: ||connection||');
          });
          this.socket.on('disconnect', (err) => {
            this.onDisconnect && this.onDisconnect(err);
            console.debug('SOCKET: ||disconnect||');
          });
          this.socket.on('connect', (e) => {
            this.socket.removeAllListeners('common-event');

            this.onConnect && this.onConnect(e);
            console.debug('SOCKET: ||connected||');
            resolve();
          });
          this.socket.on('reconnecting', (e) => {
            this.onReconnecting && this.onReconnecting(e);
            console.debug('SOCKET: ||reconnection||');
          });
          this.socket.on('reconnect', (e) => {
            this.onReconnect && this.onReconnect(e);
            console.debug('SOCKET: ||reconnected||');
          });
        }
      } else {
        this.socket.disconnect();
      }
    });
  };

  on = async (method, callback) => {
    if (this.socket) {
      let cb = async (param) => {
        await callback(param);
      };
      this.socket.on(method, cb);
    }
  };

  emit = async ({ method, params }) => {
    if (!this.socket?.connect) {
      await this.initSocket(true);
    }
    return new Promise((resolve, reject) => {
      this.socket.emit(method, params, function (err, data) {
        !err
          ? resolve(data)
          : reject(typeof err === 'object' ? JSON.stringify(err) : err);
      });
    });
  };

  async bulk({ method, params }) {
    if (!this.socket?.connect) {
      throw createError('error connect');
    }

    if (!params?.length) return;
    return new Promise((resolve, reject) => {
      let bulk = params.map((item) => ({
        method: method,
        params: item,
      }));
      this.socket.emit('bulk.parallel', { bulk }, (err, data) => {
        !err
          ? resolve(data.results)
          : reject(typeof err === 'object' ? JSON.stringify(err) : err);
      });
    });
  }

  removeAllListeners = async (event) => {
    await this.socket.removeAllListeners(event);
  };

  destroy = () => {
    this.socket.disconnect();
  };
}

export const socketInstance = _socketInstance;
