import { InterceptCamerasDetailed, Search } from '@app/components';
import { Button } from '@component/Map/components';
import { ReactComponent as ArrowLeftIcon } from '@component/Map/icons/arrow-left.svg';
import { ReactComponent as RingIcon } from '@component/Map/icons/ring.svg';
import { inject, observer } from 'mobx-react';

export const SideInfo = (props) => {
  const { isShow, onToggle } = props;
  return (
    <div
      // onCloseDrawer={onClose}
      className={`z-[501] flex relative flex-col transition-all duration-300 ${
        isShow ? 'w-[320px] min-w-[320px] h-auto min-h-screen' : 'w-0 '
      }`}>
      <SideInfoRingButton isShow={isShow} onToggle={onToggle} />
      {isShow && (
        <>
          <div className="flex items-center w-full flex-col max-h-screen min-h-screen top-0 sticky">
            <div className="w-full h-14 p-4 pb-0">
              <Search />
            </div>
            <div
              className="flex flex-col w-full"
              style={{ height: 'calc(100vh - 56px' }}>
              <InterceptCamerasDetailed />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const SideInfoRingButton = inject(({ store }) => ({
  notificationsCount: store.eventsInterceptions.mapEventsNotify,
}))(
  observer(({ isShow, onToggle, notificationsCount }) => {
    return (
      <Button
        className={` ${isShow ? '-left-12' : '-left-24'} top-4 z-top
        bg-white dark:bg-bg-3 absolute
        flex items-center
        p-2 gap-2  rounded-[100px]
        cursor-pointer
        transition-all 
        duration-200 shadow-surface
        `}
        onClick={onToggle}>
        <ArrowLeftIcon
          className={`min-w-6 min-h-6 h-6 w-6 stroke-current fill-[none] text-dark dark:text-white ${
            isShow && 'rotate-180'
          }`}
        />
        {!isShow && (
          <div className={'min-w-4 realtive mr-2'}>
            <RingIcon
              className={` fill-current ${
                notificationsCount
                  ? 'text-accent '
                  : 'text-dark dark:text-white'
              }   `}
            />
            {notificationsCount > 0 && (
              <span
                className={
                  'text-[8px] px-1 bg-white rounded-full font-bold text-accent absolute min-w-3 h-3 flex items-center justify-center top-2 shadow z-10 right-2'
                }>
                {notificationsCount > 999 ? '999+' : notificationsCount}
              </span>
            )}
          </div>
        )}
      </Button>
    );
  }),
);
