import { formatTimestampByPattern, IMAGES_TYPE } from '@app/utils';
import { uniqBy } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { IconRefresh } from '@app/assets';

export const InterceptEventDetailed = inject(({ store }) => ({
  isLoading: store.eventsInterceptions.isLoading,
  current: store.eventsInterceptions.current ?? {},
  updateCurrent: async () =>
    (await store.eventsInterceptions.getCurrentById(
      store.eventsInterceptions.current?.event,
    )) ?? {},
  setShowDrawer: store.ui.setShowDrawer,
  setImageModalInfo: store.eventsInterceptions.setImageModalInfo,
  reasons: store.wantedInterceptions.reasonsList ?? [],
}))(
  observer(
    ({
      updateCurrent,
      current,
      isLoading,
      setShowDrawer,
      setImageModalInfo,
      reasons,
    }) => {
      const { t } = useTranslation(['inputs', 'buttons']);

      const commentsList = current.event?.comment
        ? current.event?.comment?.split(';') || [current.event?.comment]
        : [];

      const openInModal = (image) => {
        if (image.type !== IMAGES_TYPE.PLATE) {
          setImageModalInfo(image);
          setShowDrawer('imageModal', true);
        }
      };

      return (
        <div
          className={`max-w-full p-4 overflow-y-auto  text-primary dark:text-white`}>
          <div
            className={'py-4 gap-1 flex items-center border-b border-b-line'}>
            <button disabled={isLoading} onClick={updateCurrent}>
              <IconRefresh />
            </button>
            <p className={'font-medium text-h1'}>
              {current.event?.plate_number} {isLoading ? 'Загрузка...' : ''}
            </p>
          </div>
          {current.event?.plate_number && (
            <Fragment>
              <div className={'flex flex-col flex-wrap border-b border-b-line'}>
                {uniqBy(
                  [...(current.files || []), ...(current.files_fast || [])],
                  'url',
                )
                  ?.sort()
                  .map((f) => {
                    return (
                      <button
                        key={f.url}
                        className="contents"
                        disabled={f.type === IMAGES_TYPE.PLATE}
                        onClick={() => openInModal(f)}>
                        <img
                          className={`max-h-[300px] bg-bg min-h-[300px] mb-1 ${
                            f?.type === IMAGES_TYPE.PLATE && 'w-full h-auto'
                          }`}
                          src={f?.url}
                          alt={f?.type}
                        />
                      </button>
                    );
                  })}
              </div>
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('description')}
                </span>
                <p>{current.event?.description}</p>
              </div>
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('controlClassifier')}
                </span>
                <p>{reasons[current.event?.reason] ?? '???'}</p>
              </div>
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('time')}
                </span>
                <p>
                  {formatTimestampByPattern({
                    timestamp: current.event?.datetime,
                    pattern: 'DD-MM-YYYY HH:mm:ss',
                  })}
                </p>
              </div>

              {current.event?.comment && (
                <div>
                  <span
                    className={'gradient-accent text-p3 font-bold uppercase'}>
                    {t('comment')}
                  </span>
                  <p className={'flex flex-col '}>
                    {commentsList.map((item, i) => (
                      <span key={i} className={'my-2'}>
                        {item}
                      </span>
                    ))}
                  </p>
                </div>
              )}
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('address')}
                </span>
                <p>{current.camera?.data?.description ?? ''}</p>
              </div>
              {current.wanted?.id && (
                <div className={'py-4 border-b border-b-line'}>
                  <a
                    target={'_blank'}
                    href={`#/wanted/${current.wanted?.id}/edit`}
                    className={
                      'gradient-accent underline text-p3 font-bold uppercase'
                    }
                    rel="noreferrer">
                    {t('orientation', { ns: 'buttons' })}
                  </a>
                </div>
              )}
            </Fragment>
          )}
        </div>
      );
    },
  ),
);
