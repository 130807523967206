import React from 'react';

export const IconRefresh = (props) => {
  const { className } = props;
  return (
    <svg
      width="24"
      height="24"
      className={`fill-current ${className || ''}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M7.74944 5.18011C8.00063 5.50947 7.93726 5.9801 7.6079 6.23128C5.81958 7.59515 4.75 9.70821 4.75 12C4.75 15.736 7.57584 18.812 11.2067 19.2071L10.5303 18.5303C10.2374 18.2374 10.2374 17.7626 10.5303 17.4697C10.7966 17.2034 11.2133 17.1792 11.5069 17.3971L11.591 17.4697L13.591 19.4697C13.8573 19.7359 13.8815 20.1526 13.6636 20.4462L13.591 20.5303L11.591 22.5303C11.2981 22.8232 10.8232 22.8232 10.5303 22.5303C10.2641 22.2641 10.2399 21.8474 10.4577 21.5538L10.5303 21.4697L11.2806 20.7208C6.78461 20.355 3.25 16.5903 3.25 12C3.25 9.23526 4.54179 6.68321 6.69827 5.03856C7.02763 4.78738 7.49826 4.85075 7.74944 5.18011ZM13.4697 1.46967C13.7626 1.76256 13.7626 2.23744 13.4697 2.53033L12.7204 3.27923C17.2159 3.6456 20.75 7.41008 20.75 12C20.75 14.6445 19.5687 17.0974 17.5692 18.7491C17.2498 19.0129 16.7771 18.9679 16.5133 18.6485C16.2495 18.3292 16.2946 17.8564 16.6139 17.5926C18.2721 16.2229 19.25 14.1922 19.25 12C19.25 8.26436 16.4247 5.18861 12.7943 4.79301L13.4697 5.46967C13.7626 5.76256 13.7626 6.23744 13.4697 6.53033C13.1768 6.82322 12.7019 6.82322 12.409 6.53033L10.409 4.53033C10.1161 4.23744 10.1161 3.76256 10.409 3.46967L12.409 1.46967C12.7019 1.17678 13.1768 1.17678 13.4697 1.46967Z" />
    </svg>
  );
};
