import { Sidebar } from '@app/components';
import ErrorBoundary from '@component/ErrorBoundary';

export const PageContainer = ({
  children,
  className = 'flex flex-col items-center  w-full  pt-12  px-4 max-w-[calc(100vw_-_313px)]',
}) => {
  return (
    <div className={'flex flex-row w-full h-full'}>
      <Sidebar />
      <main className={`  ${className} `} style={{ minHeight: '50vh' }}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
    </div>
  );
};
