import { ReasonsWantedSelect } from '@component/LibSelects/ReasonsWantedSelect';
import { Filter } from '@component/Map/components/Filter';
import { InputDate, InputSearch } from '@component/ui';
import { useSyncFilterWithRoute } from '@hooks/useSyncFilterWithRoute';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';

import { ReactComponent as FilterButton } from '../icons/filter.svg';
import { useTranslation } from 'react-i18next';

export const MapFilter = inject(({ store }) => ({
  onLoadFilter: store.eventsInterceptions.filterModule.setFilter,
  onFilter: store.eventsInterceptions.filterModule.onFilter,
  eventsFilter: store.eventsInterceptions.filterModule.filter,
}))(
  observer(({ onFilter, eventsFilter, onLoadFilter }) => {
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const toggleFilter = useCallback(
      () => setIsOpenFilter((prevState) => !prevState),
      [],
    );
    const { t } = useTranslation('inputs');

    const [filter] = useSyncFilterWithRoute(eventsFilter, onLoadFilter);

    const onSearch = useCallback((search) => {
      onFilter({ search, pageSize: search !== '' ? 100 : 10 });
    }, []);
    const onChangeType = useCallback((reason) => {
      onFilter({ reason });
    }, []);
    const onChangeDate = useCallback((key, value) => {
      if (value && key === 'dateTo') {
        value.setHours(23, 59, 59, 999);
      }
      onFilter({
        [key]: value,
      });
    }, []);

    useEffect(() => {
      onFilter(filter);
    }, []);

    return (
      <div>
        <div className={' flex items-center shadow-surface rounded-[56px]'}>
          <InputSearch
            className={'relative !h-[40px] !border-none'}
            labelText={t('searchGRNZ')}
            callback={onSearch}
            value={filter.search}>
            <FilterButton
              className={`fill-[none] w-6 h-full cursor-pointer stroke-current 
                  absolute right-4 hover:text-accent duration-200 transition-colors
                ${
                  isOpenFilter
                    ? 'text-accent'
                    : 'text-secondary dark:text-white'
                }
                `}
              onClick={toggleFilter}
            />
          </InputSearch>
        </div>
        <Filter isOpen={isOpenFilter}>
          <h4 className={'text-h3 font-semi text-primary uppercase'}>
            {t('filtered', { ns: 'inputs' })}
          </h4>
          <div className={'flex flex-col'}>
            <div className={'flex items-center'}>
              <ReasonsWantedSelect
                className={' underlined w-full max-w-full'}
                type={'text'}
                labelText={t('reason', { ns: 'inputs' }) + ' *'}
                value={filter.reason}
                callback={onChangeType}
              />
            </div>
            <div className={'flex items-center mt-4'}>
              <InputDate
                className={'h-full underlined_date'}
                label={t('from', { ns: 'inputs' })}
                value={filter.dateFrom}
                callback={(v) => onChangeDate('dateFrom', v)}
              />
              <InputDate
                className={'h-full ml-4 underlined_date'}
                label={t('to', { ns: 'inputs' })}
                value={filter.dateTo}
                callback={(v) => onChangeDate('dateTo', v)}
              />
            </div>
          </div>
        </Filter>
      </div>
    );
  }),
);
