import { inject, observer } from 'mobx-react';
import React from 'react';

import iconError from '../../assets/icons/svg/iconNotFound.svg';
import { getFileByPath } from '../../hooks';
import { onEnterKeyPressDoCallback } from '@app/utils';
import { PreloaderIcon } from './PreloaderIcon';

let LoadedClass = 'lazy-img-loaded ',
  PlaceholderClass = 'lazy-img-loading',
  ErrorClass = 'lazy-img-error';

export const LazyImage = inject(({ store }) => ({
  token: store.auth.token,
}))(
  observer(
    class _LazyImage extends React.Component {
      _isMounted = false;
      // loadedClass="lazy-img-loaded "
      // placeholderClass="lazy-img-loading"
      // errorClass="lazy-img-error"
      constructor(props) {
        super(props);
        const { placeholderClass = PlaceholderClass, placeholder } = this.props;
        this.image = new Image();
        this.state = this._getImageState(
          placeholderClass + ' ' + LoadedClass,
          placeholder,
        );
      }
      async componentDidUpdate(prevProps, prevState, snapshot) {
        const { loadPlaceholder, placeholder, token } = this.props;

        if (loadPlaceholder) {
          this.image = new Image();
          this._imageLoad(placeholder, this._initialise);
        }
        if (token !== prevState.token || token !== prevProps.token) {
          this.setState({ token });
          await this._initialise();
        }
      }

      componentWillUnmount() {
        this._isMounted = false;
      }
      async componentDidMount() {
        this._isMounted = true;
        const { loadPlaceholder, placeholder } = this.props;
        if (loadPlaceholder) {
          return this._imageLoad(placeholder, this._initialise);
        }
        await this._initialise();
      }

      _initialise = async () => {
        const { src, token } = this.props;

        const _img = await getFileByPath(src, token);

        this._imageLoad(
          _img,
          () => this._imageLoaded(_img),
          () => this._imageError(),
        );
        // try {
        //   const response = await axiosInstance.get(_img, {
        //     responseType: "blob",
        //   });
        //
        //   let reader = new FileReader();
        //   reader.readAsDataURL(response.data);
        //   reader.onloadend = () => {
        //     imgBase64 = reader.result;
        //     this._imageLoad(
        //       imgBase64,
        //       () => this._imageLoaded(imgBase64),
        //       this._imageError
        //     );
        //   };
        // } catch (e) {
        //   this._imageError();
        //   console.debug(e, "|||||fetch img error|||||");
        // }
      };

      _imageLoad = (src, loaded = () => {}, error = () => {}) => {
        this.image.onload = loaded;
        this.image.onerror = (e) => {
          error();
        };
        this.image.srcObject = src;
        this.image.src = src;
      };

      _imageLoaded = (src) => {
        const { onImageLoad, loadedClass = LoadedClass } = this.props;
        let imgState = this._getImageState(loadedClass, src);
        if (this._isMounted) {
          this.setState(imgState);
          onImageLoad && onImageLoad(this.image);
        }
      };

      _imageError = (event) => {
        const { onImageError } = this.props;
        let imgState = this._getImageState(
          'error dark:bg-dark-primary ',
          iconError,
        );
        if (this._isMounted) {
          this.setState(imgState);

          onImageError && onImageError();
        }
      };

      _getImageState = (currentClass, imageSrc) => {
        const { className, token } = this.props;
        return {
          applyClass: [className, currentClass],
          image: imageSrc,
          token,
          tryCount: this.state?.tryCount === 1 ? this.state?.tryCount + 1 : 1,
        };
      };
      render() {
        const { type, src, style, onClick, refImg } = this.props;
        const { image, applyClass } = this.state;
        const hasCallback = !!onClick;
        switch (type) {
          case 'background':
            let backgroundImage = { backgroundImage: `url(${image})` };
            return (
              <div
                className={`${applyClass.join(' ')} img-lazy dark:bg-dark `}
                style={style || null}>
                <PreloaderIcon className="text-accent" />
                <div key={this.state.token + src} style={backgroundImage} />
              </div>
            );
          default:
            return (
              <div
                tabIndex={hasCallback ? 0 : -1}
                onClick={hasCallback ? onClick : null}
                onKeyUp={(e) =>
                  hasCallback ? onEnterKeyPressDoCallback(e, onClick) : null
                }
                style={style || null}
                className={`bg-border overflow-hidden img-lazy ${
                  hasCallback ? 'focus:outline-black' : ''
                } ${applyClass.join(' ')}`}>
                <PreloaderIcon className="text-accent" />
                <img
                  key={this.state.token + src}
                  src={image}
                  alt={'Картинка по адресу ' + src}
                  ref={refImg ?? null}
                />
              </div>
            );
        }
      }
    },
  ),
);
