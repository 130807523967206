import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

import { SideInfo } from '@app/components';
import { formatTimestampByPattern, mapTilesUrl } from '@app/utils';
import L from 'leaflet';
import { inject, observer } from 'mobx-react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import {
  Button,
  MapFilter,
  SwitchMapEventsFlow,
  SwitchMapSourcesShow,
} from './components';
import svgBlackCameraMarker from './icons/black-camera.svg';
import { ReactComponent as CameraIcon } from './icons/camera.svg';
import { ReactComponent as DetailsIcon } from './icons/details.svg';
import svgRedMarker from './icons/red-marker.svg';
import styles from './styles/Map.module.css';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const redMarker = new L.icon({
  iconUrl: svgRedMarker,
  iconSize: [60, 60],
});

// const blackMarker = new L.icon({
//   iconUrl: svgBlackMarker,
//   iconSize: [60, 60],
// });

const popupGRNZIcon = (plateNum) => {
  return new L.divIcon({
    className: `${styles.popupGRNZIcon} ${styles.red}`,
    iconUrl: svgBlackCameraMarker,
    iconSize: [88, 32],
    html: `<div>${plateNum}</div>`,
  });
};

const blackCameraMarker = new L.icon({
  iconUrl: svgBlackCameraMarker,
  iconSize: [60, 60],
});

export const Map = observer(() => {
  const [isShow, setIsShow] = useState(false);
  const { t } = useTranslation('buttons');

  return (
    <div className={'relative flex w-full h-full'}>
      <header className={`${styles.header} ${isShow && styles.headerSm}`}>
        <div className={'flex items-center gap-2 flex-wrap mr-[200px]'}>
          <MapFilter />
          <SwitchMapEventsFlow />
          <SwitchMapSourcesShow />
          <ButtonClearMap />
        </div>
      </header>
      <MapEvents />
      <SideInfo
        isShow={isShow}
        onToggle={() => setIsShow((prevState) => !prevState)}
      />
    </div>
  );
});

export const ButtonClearMap = inject(({ store }) => ({
  clearEvents: store.eventsInterceptions.clearEvents,
}))(
  observer(({ clearEvents }) => {
    const { t } = useTranslation('buttons');
    return (
      <Button
        onClick={clearEvents}
        className={`${styles.clear} transition-all duration-300 text-primary dark:text-white bg-white dark:bg-bg-3`}>
        {t('clearMap')}
      </Button>
    );
  }),
);

const ChangeView = inject(({ store }) => ({
  markers: store.eventsInterceptions.mapEvents,
  mapCenter: store.eventsInterceptions.map.center,
  mapZoom: store.eventsInterceptions.map.zoom,
}))(
  observer(({ mapCenter, mapZoom, markers }) => {
    const map = useMap();
    useEffect(() => {
      if (markers.length) {
        const markerBounds = L.latLngBounds({});
        markers.forEach((marker) => {
          markerBounds.extend(marker.coordinates);
        });
        try {
          map.fitBounds(markerBounds);
        } catch (e) {
          console.error(e, '?');
        }
      }
    }, [markers]);
    useEffect(() => {
      map.setView({ lat: mapCenter[0], lng: mapCenter[1] }, mapZoom);
    }, []);
    return <>{JSON.stringify(mapZoom, null, 2)}</>;
  }),
);

const MapEvents = inject(({ store }) => ({
  mapCenter: store.eventsInterceptions.map.center,
  mapZoom: store.eventsInterceptions.map.zoom,
}))(
  observer(({ mapCenter, mapZoom }) => {
    return (
      <MapContainer
        scrollWheelZoom={true}
        center={mapCenter}
        zoom={mapZoom}
        minZoom={3}
        className="z-0 !bg-map">
        <TileLayer url={mapTilesUrl} />
        <ChangeView />
        <MapEventsCluster />
        <MapSourcesCluster />
      </MapContainer>
    );
  }),
);

const MapEventsCluster = inject(({ store }) => ({
  events: store.eventsInterceptions.mapEvents,
  getCurrentEvent: store.eventsInterceptions.getCurrentById,
  onOpenDrawer: (value) => store.ui.setShowDrawer('interceptEvent', value),
}))(
  observer(({ events, getCurrentEvent, onOpenDrawer }) => {
    const onViewDetailed = useCallback(
      ({ id, datetime }) =>
        async () => {
          getCurrentEvent({ id, datetime });
          await onOpenDrawer(true);
        },
      [],
    );

    return (
      <MarkerClusterGroup
        iconCreateFunction={createClusterEventsIcon}
        spiderLegPolylineOptions={{
          weight: 0,
          opacity: 0,
        }}>
        {events?.map((event) => (
          <Marker
            key={event.id}
            position={[event.coordinates[0], event.coordinates[1]]}
            icon={
              event?._reason !== 'unknown-driver'
                ? redMarker
                : popupGRNZIcon(event?.plateNum)
            }>
            <Popup key={event.id}>
              <div className={'dark:bg-bg-3 bg-bg'}>
                <div
                  className={`${styles.popupHeader} py-1  bg-bg-2 dark:bg-bg-3-primary text-secondary dark:text-white`}>
                  <CameraIcon className={'w-6 h-6 min-w-6 fill-[none]'} />
                  <div className={styles.title}>{event?.origin_name}</div>
                </div>
                <div className={styles.popupBody}>
                  <div className={styles.popupContent}>
                    <h3>{event.plateNum}</h3>
                  </div>
                  <div className={styles.description}>{event.description}</div>
                  <div className={`${styles.description} dark:text-white`}>
                    {' '}
                    {formatTimestampByPattern({
                      timestamp: event?.datetime,
                      pattern: 'DD-MM-YYYY HH:mm:ss',
                    })}
                  </div>
                </div>
                <div className={styles.popupFooter}>
                  {/* <button className={'text-primary dark:text-white'}>
                    <RouteIcon />
                    Маршрут
                  </button> */}
                  <button
                    className={'text-primary dark:text-white'}
                    onClick={onViewDetailed(event)}>
                    <DetailsIcon />В детали
                  </button>
                </div>
              </div>
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    );
  }),
);

const MapSourcesCluster = inject(({ store }) => ({
  sources: store.camerasInterceptions.allListTable,
}))(
  observer(({ sources }) => {
    return (
      <MarkerClusterGroup
        iconCreateFunction={createClusterCamerasIcon}
        spiderLegPolylineOptions={{
          weight: 0,
          opacity: 0,
        }}>
        {sources?.map(
          (s) =>
            s.latlng[0] && (
              <Marker
                key={s.id}
                position={[s.latlng[0], s.latlng[1]]}
                icon={blackCameraMarker}>
                <Popup>
                  <div className={styles.popupBody}>
                    <div className={styles.popupContent}>
                      {/* <h3>ID{event.id.toUpperCase()}</h3> */}
                      <div className={styles.popupCameraIcon}>
                        <CameraIcon className={'w-4 h-4 min-w-4 fill-[none]'} />
                      </div>
                      <div className={`${styles.description}`}>
                        {s.latlng[0]}, {s.latlng[1]}
                      </div>
                    </div>
                    <h5 className="mt-2 text-dark dark:text-white">{s.name}</h5>
                  </div>
                </Popup>
              </Marker>
            ),
        )}
      </MarkerClusterGroup>
    );
  }),
);

const createClusterCamerasIcon = (cluster) => {
  const count = cluster.getChildCount();
  let size = 'LargeXL';

  if (count < 10) {
    size = 'Small';
  } else if (count >= 10 && count < 100) {
    size = 'Medium';
  } else if (count >= 100 && count < 500) {
    size = 'Large';
  }
  const options = {
    cluster: `markerCluster${size}`,
  };

  return L.divIcon({
    html: `<div class="bg-gradient-accent shadow-surface text-white rounded-full p-4 min-w-4 h-4 flex items-center justify-center">
        <span class="markerClusterLabel">${count}</span>
      </div>`,
    className: `${options.cluster}`,
  });
};

const createClusterEventsIcon = (cluster) => {
  const count = cluster.getChildCount();
  let size = 'LargeXL';

  if (count < 10) {
    size = 'Small';
  } else if (count >= 10 && count < 100) {
    size = 'Medium';
  } else if (count >= 100 && count < 500) {
    size = 'Large';
  }
  const options = {
    cluster: `markerCluster${size}`,
  };

  return L.divIcon({
    html: `<div class="bg-alert shadow-surface text-white rounded-full p-4 min-w-4 h-4 flex items-center justify-center">
        <span class="markerClusterLabel">${count}</span>
      </div>`,
    className: `${options.cluster}`,
  });
};
