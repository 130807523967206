import './utils/i18n';
import './App.css';

import { store } from '@app/stores';
import { loadEventListeners } from '@app/stores/helpers';
import { InterceptEventDetailedDrawer } from '@component/Drawer/InteceptEventDrawer';
import { InterceptWantedDetailedDrawer } from '@component/Drawer/InteceptWantedDrawer';
import { ImageModal } from '@component/Modals/ModalImage';
import { createHashHistory } from 'history';
import { when } from 'mobx';
import { inject, observer, Provider } from 'mobx-react';
import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';

import { Notifier, ScrollToTop } from './components/';
import { MainLayout } from './layouts';
import { AuthorizationPage } from './pages';
import { Routes } from './router/Routes';
import { socketService } from './stores/_shared/socketService.module';

export const history = createHashHistory();

const App = () => {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
};

const Access = inject(({ store }) => {
  return {
    checkIsLogged: store.auth.checkIsLogged,
    logout: store.auth.logout,
    setToken: store.auth.setToken,
    checkIsDarkTheme: store.ui.checkIsDarkTheme,
    getLib: store.wantedInterceptions.getLib,
  };
})(
  observer((props) => {
    const { checkIsLogged, checkIsDarkTheme, logout, getLib } = props;

    useEffect(() => {
      console.debug('app version: ', window.APP_VERSION);
      socketService.init(true).then(async () => {
        const logged = await checkIsLogged();
        if (logged) {
          await when(() => socketService.isConnected);

          await getLib();
          socketService.on('api-logout', (e) => {
            logout();
          });
        }
        checkIsDarkTheme();
      });
    }, []);
    useEffect(() => {
      if (socketService.isConnected) {
        loadEventListeners();
      }
    }, [socketService.isConnected]);

    return <></>;
  }),
);
const Main = inject(({ store }) => ({
  isAuth: store.auth.isAuth,
}))(
  observer((props) => {
    const { isAuth } = props;

    return (
      <div
        className={`flex flex-row  min-h-screen w-full bg-white transition duration-300 dark:bg-dark  `}>
        <Notifier />
        <Access />
        <Router history={history}>
          <ScrollToTop>
            <MainLayout>
              {!isAuth ? <AuthorizationPage /> : <Routes />}
              <InterceptEventDetailedDrawer />
              <InterceptWantedDetailedDrawer />
              <ImageModal />
            </MainLayout>
          </ScrollToTop>
        </Router>
      </div>
    );
  }),
);

export default App;
