import { eventsApi } from '@app/common/api/events';
import { FilterModule } from '@app/stores/_shared/filterModule';
import { authService } from '@app/stores/mainStore.store';
import { baseApiUrl, formatTimestampByPattern, requestAPI } from '@app/utils';
import dayjs from 'dayjs';
import { escape, isNil, keyBy } from 'lodash-es';
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';

import { tokenService } from './helpers';

const defaultLtnLng = [49, 73];

// Удалить autorun или перенести в Module
class eventsInterceptionsStore {
  filterModule = undefined;
  list = [];
  _listStatic = [];
  map = {
    center: defaultLtnLng,
    zoom: 4,
  };
  liveData = [];
  current = {};
  isLoading = false;
  isMapFlowEnabled = false;
  mapMarkers = {
    camera: [],
    origin: [],
    directions: [],
  };

  filterForm = {
    plate_number: '',
    reason: [],
    plate_country: [],
    taxonomy: [],
    camera: [],
  };

  filterMapEvents = {
    search: '',
    reason: [],
    dateFrom: '',
    dateTo: '',
  };
  imageModalInfo = {
    url: '',
    type: '',
  };

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.isMapFlowEnabled,
      (enabled) => {
        enabled ? this.subscribeLive() : this.unsubscribeLive();
      },
    );
    this.filterModule = new FilterModule(
      {
        page: 0,
        limit: 10,
        sortBy: [],
        search: '',
        dateFrom: dayjs().subtract(3, 'days').startOf('day').toDate(),
        dateTo: dayjs().endOf('day').toDate(),
        reason: [],
      },
      this.getList,
    );
  }

  get totalItems() {
    return this.filterModule.totalItems;
  }

  get mapEvents() {
    return [...(this.list || []), ...(this.liveData || [])]
      .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
      .filter((e) => e.latlng?.length && e.latlng.every((c) => c !== null))
      .map((e) => {
        return {
          id: e.id,
          datetime: e.datetime,
          date: formatTimestampByPattern({
            timestamp: e.datetime,
            pattern: 'HH:mm:ss DD.MM.YYYY',
          }),
          origin_name: e.origin_name,
          plateNum: e.plate_number,
          description: e.description,
          _reason: e.reason,
          coordinates: e.latlng?.map((v) => (v ? v : 0)),
          notify: e.notify,
        };
      });
  }

  get mapEventsDetailed() {
    return [...(this.list || []), ...(this.liveData || [])]
      .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
      .map((e) => {
        return {
          id: e.id,
          datetime: e.datetime,
          date: formatTimestampByPattern({
            timestamp: e.datetime,
            pattern: 'HH:mm:ss DD.MM.YYYY',
          }),
          origin_name: e.origin_name,
          plateNum: e.plate_number,
          description: e.description,
          _reason: e.reason,
          coordinates: e.latlng?.map((v) => (v ? v : 0)),
          notify: e.notify,
        };
      });
  }

  get mapEventsNotify() {
    return [...(this.list || []), ...(this.liveData || [])].filter(
      (e) => e.notify,
    )?.length;
  }

  get listTable() {
    return this.list.map((c) => ({
      ...c,
      index: c.id,
      action: {
        id: c.id,
        datetime: c.datetime,
        label: c.plate_number,
        can: c._can,
      },
    }));
  }

  filterChange = ({ search, reason, dateFrom, dateTo }) => {
    this.filterMapEvents = {
      ...this.filterMapEvents,
      search: !isNil(search) ? search : this.filterMapEvents.search,
      reason: !isNil(reason) ? reason : this.filterMapEvents.reason,
      dateFrom:
        dateFrom !== undefined ? dateFrom : this.filterMapEvents.dateFrom,
      dateTo: dateTo !== undefined ? dateTo : this.filterMapEvents.dateTo,
    };
    this.mapEventsFilter();
  };

  mapEventsFilter() {
    let filtered = this._listStatic;
    let filter = this.filterMapEvents;
    if (filter.search) {
      filtered = filtered.filter(function (str) {
        return str.plate_number.indexOf(filter.search) !== -1;
      });
    }
    if (filter.reason && filter.reason.length > 0) {
      filtered = filtered.filter(function (l) {
        return filter.reason.find((r) => r.label === l.description);
      });
    }
    if (filter.dateFrom) {
      filtered = filtered.filter(function (l) {
        return new Date(filter.dateFrom) < new Date(l.datetime);
      });
    }
    if (filter.dateTo) {
      filtered = filtered.filter(function (l) {
        return new Date(l.datetime) < new Date(filter.dateTo);
      });
    }
    this.setList(filtered);
    // return filtered;
  }

  notifyRead = (id) => {
    const readNotification = (arr) =>
      arr.map((e) => {
        return {
          ...e,
          notify: id === e.id ? false : e.notify,
        };
      });
    const [list, liveData] = [this.list, this.liveData].map(readNotification);

    this.setList(list);
    this.setLiveData(liveData);
  };

  setIsLoading = (loading) => (this.isLoading = loading);

  setImageModalInfo = (info) => (this.imageModalInfo = info);

  clear = () => {
    this.setList([]);
    this.clearCurrent();
  };

  clearMapEventsFilter = () => {
    this.filterMapEvents = {
      search: '',
      reason: [],
      dateFrom: '',
      dateTo: '',
    };
    this.mapEventsFilter();
  };

  setIsMapFlowEnabled = (enabled) => (this.isMapFlowEnabled = enabled);

  setCurrent = (e) => (this.current = e);

  setLiveData = (arr) => (this.liveData = arr);

  clearCurrent = () => this.setCurrent({});

  clearFilterMapEvents = (filter) => (this.filterMapEvents = filter);

  getCurrentFromListById = (id) => {
    const [event] = (this.current = this.list.filter((wanted) => {
      if (wanted.id === +id) return wanted;
    }));
    return event;
  };

  getCurrentById = async ({ id, datetime }) => {
    const { hasError } = await requestAPI({
      func: async () =>
        await eventsApi.getById({
          event: { datetime, id },
          include: ['files', 'files_fast', 'camera', 'server', 'wanted'],
        }),
      onLoad: (v) => {
        runInAction(async () => {
          this.current = {
            ...v,
            files: await Promise.all(
              (v.files || [])
                .map(async (f) => ({
                  ...f,
                  url: f.url,
                }))
                .sort((v, b) => v.type - b.type),
            ),
            _wanted: v.wanted || {},
            _server: v.server || {},
            _camera: v.camera || {},
            _detailShown: false,
          };

          this.liveData.forEach((item) => {
            if (item.id === id) {
              item.files = toJS(this.current.files);
              item.files_fast = toJS(this.current.files_fast);
              item.latlng = toJS(this.current.event.latlng);
              item.coordinates = toJS(this.current.event.latlng);
            }
          });
        });
      },
      onErrorMessage: {
        message: 'Ошибка получения ',
        callback: () => this.getCurrentById(id),
      },
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  setList = (wantedList) => {
    this.list = wantedList;
  };

  setListStatic = (wantedList) => {
    this._listStatic = wantedList;
  };

  async getFilter(search) {
    const filter = {};

    // const cameraIds = [...this.filterForm.camera];
    //
    // if (this.filterForm.taxonomy.length) {
    //   const { cameras } = await cameraApi.getList({
    //     params: {
    //       filter: {
    //         _taxonomies: { $in: this.filterForm.taxonomy },
    //       },
    //     },
    //   });
    //
    //   cameras.forEach((camera) => cameraIds.push(camera.id));
    // }
    //
    // if (cameraIds.length) {
    //   filter.camera = {
    //     $in: cameraIds,
    //   };
    // }

    return filter;
  }

  getList = async ({
    limit,
    page,
    sortBy,
    search,
    dateFrom,
    dateTo,
    reason,
  }) => {
    const params = {
      can: ['update', 'delete'],
      include: ['cameras', 'certificate'],
      limit: limit,
      offset: (page ?? 0) * (limit || 10),
      sort: { datetime: 'desc' },
    };

    if (sortBy) {
      params.sort = Object.entries(sortBy).reduce((acc, [key, value]) => {
        acc[key] = value === 1 ? 'asc' : 'desc';
        return acc;
      }, {});
    }
    params.filter = await this.getFilter();

    if (search && search !== '*') {
      if (search.includes('*')) {
        params.filter.plate_number = {
          $like: search.replace(/\*/g, '%'),
        };
      } else {
        const manyGrnzSearch = search.split(',').filter(Boolean);

        if (manyGrnzSearch?.length) {
          params.filter.plate_number = {
            $or: manyGrnzSearch.map((item) => {
              return {
                $like: item.trim(),
              };
              // return {
              //   $regex: escapeRegExp(item.trim()),
              //   $options: 'i',
              // }
            }),
          };
        } else {
          params.filter.plate_number = search;
        }
      }
    }

    params.filter.datetime = {
      $lte: dateTo ? dayjs(dateTo).format() : dayjs().endOf('day').format(),
      $gte: dateFrom
        ? dayjs(dateFrom).format()
        : dayjs().subtract(3, 'days').startOf('day').format(),
    };

    if (reason?.length) {
      params.filter.reason = {
        $in: reason.map((option) => {
          return option.value;
        }),
      };
    }

    await requestAPI({
      func: async () => await eventsApi.getList({ params }),
      onLoad: async (results) => {
        const cameras = keyBy(results.cameras, 'id');

        const data = results.events || [];
        this.mapMarkers.directions = results.directions || [];

        const list = await Promise.all(
          data.map(async (v) => ({
            ...v,
            files: await Promise.all(
              (v.files || []).map(async (f) => ({
                ...f,
                url: f.url,
              })),
            ),
            _camera: cameras[v.camera] || {},
            _detailShown: false,
          })),
        );

        this.filterModule.totalItems = results.count ?? 0;

        this.setList(list);
        this.setListStatic(list);
      },
      onErrorMessage: {
        message: 'Ошибка получения ориентировок ',
        callback: () =>
          this.getList({
            limit,
            page,
            sortBy,
            search,
            dateFrom,
            dateTo,
            reason,
          }),
      },
      onLoading: this.setIsLoading,
    });
  };
  doExport = ({ search, page, limit, reason, tags, tagsEmpty }) => {
    const params = {
      can: ['update', 'delete'],
      include: ['account'],
      limit: this.filterModule.totalItems,
      offset: (page ?? 0) * (limit || 10),
      csv: {
        delimiter: ';',
        charset: 'windows-1251',
      },
    };
    const url = `${baseApiUrl()}/v2/interceptor.event.csv`;
    const token = tokenService.getToken();

    params.filter = this.getFilteredParams(search, reason, tags, tagsEmpty);

    const html = `
    <form method="post" action="${url}" target="_blank" id="rtms-number-csv" >
          <img name="img" src="" value=""></img>
            <input type="hidden" name="token" value="${escape(token)}" />
            <input type="hidden" name="json" value="${escape(
              JSON.stringify(params),
            )}" />
          </form>
        `;

    const div = document.createElement('div');
    div.innerHTML = html;

    document.body.appendChild(div);
    document.getElementById('rtms-number-csv').submit();
    document.body.removeChild(div);
  };
  getListByImport = async (listSearch) => {
    this.filterModule.filter.search = listSearch.join(',');
    await this.getList(toJS(this.filterModule.filter));
  };

  onEvent = async ({ event, object }) => {
    if (
      ['rtms.capture', 'rtms.capture.intercept'].includes(event) &&
      this.isMapFlowEnabled
    ) {
      this.map.zoom = 4;

      if (object.latlng?.length && object.latlng.every(Boolean)) {
        this.map.center = object.latlng;
        this.map.zoom = 13;
      }

      const _event = {
        ...object,
        files: (object.files || []).map(async (file) => ({
          ...file,
          url: file.url,
        })),
        coordinates: object.latlng || object.coordinates,
        _camera: object.camera,
        notify: true, // Yerkin add
      };

      this.liveData.push(_event);
    }
  };

  subscribeLive = async () => {
    this.map.zoom = 4;
    await requestAPI({
      func: async () =>
        await eventsApi.subscribeEvents({
          filter: toJS(this.filterForm.filterForQuery),
        }),

      onErrorMessage: {
        message: 'Не удалось подписаться на события',
        callback: () => this.subscribeLive(),
      },
    });
  };

  unsubscribeLive = async () => {
    await requestAPI({
      func: async () => await eventsApi.unsubscribeEvents({}),
      onLoad: () => {
        // this.liveData = [];
      },
      onErrorMessage: {
        message: 'Не удалось подписаться на события',
        callback: () => this.subscribeLive(),
      },
    });
  };

  clearEvents = () => {
    this.setList([]);
    this.setLiveData([]);
  };

  doExport = async ({ limit, page, sortBy, search, dateFrom, dateTo }) => {
    const reas = this.filterModule.filter?.reason;
    const reasonValue = reas.length ? reas.map((option) => option.value) : [];

    const params = {
      can: ['update', 'delete'],
      include: ['cameras', 'certificate'],
      limit: this.filterModule.totalItems,
      offset: (page ?? 0) * (limit || 10),
      sort: { datetime: 'desc' },
      reason: { $in: reasonValue },
      csv: {
        delimiter: ';',
        charset: 'windows-1251',
      },
    };
    if (sortBy) {
      params.sort = Object.entries(sortBy).reduce((acc, [key, value]) => {
        acc[key] = value === 1 ? 'asc' : 'desc';
        return acc;
      }, {});
    }

    params.filter = await this.getFilter();

    if (search && search !== '*') {
      if (search.includes('*')) {
        params.filter.plate_number = {
          $like: search.replace(/\*/g, '%'),
        };
      } else {
        params.filter.plate_number = search;
      }
    }

    params.filter.datetime = {
      $lte: dateTo
        ? dayjs(dateTo).endOf('day').format()
        : dayjs().endOf('day').format(),
      $gte: dateFrom
        ? dayjs(dateFrom).startOf('minute').format()
        : dayjs().subtract(3, 'days').startOf('day').format(),
    };

    const apiurl = authService.apiUrl;
    const url = `${apiurl}/v2/interceptor.event.csv`;
    const token = tokenService.getToken();

    const html = `
    <form method="post" action="${url}" target="_blank" id="rtms-number-csv" >
          <img name="img" src="" value=""></img>
            <input type="hidden" name="token" value="${escape(token)}" />
            <input type="hidden" name="json" value="${escape(
              JSON.stringify(params),
            )}" />
          </form>
        `;

    const div = document.createElement('div');
    div.innerHTML = html;

    document.body.appendChild(div);
    document.getElementById('rtms-number-csv').submit();
    document.body.removeChild(div);
  };
}

export { eventsInterceptionsStore };
