import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { useSlider } from '../../hooks';
import { LazyImage } from '../_other';
import { Slider } from './Slider';

export const GalleryVideoImgSlider = observer((props) => {
  const { slides } = props;
  const [slider] = useSlider(slides);
  const videoFrame_1 = useRef(null);
  const videoFrame_2 = useRef(null);
  const videoFrames = [videoFrame_1, videoFrame_2];

  const [canPlay, setCanPlay] = useState([]);

  useEffect(() => {
    const v1 = videoFrame_1.current;
    const v2 = videoFrame_2.current;

    if (v1 && v2 && canPlay.length == 2) {
      try {
        v1.addEventListener('play', () => v2.play());
        v1.addEventListener('pause', () => v2.pause());
        v2.addEventListener('play', () => v1.play());
        v2.addEventListener('pause', () => v1.pause());
      } catch (e) {}
    }

    return () => {
      if (v1 && v2) {
        v1.removeEventListener('play', () => v2.play());
        v1.removeEventListener('pause', () => v2.pause());
        v2.removeEventListener('play', () => v1.play());
        v2.removeEventListener('pause', () => v1.pause());
      }
    };
  }, [canPlay]);

  const videos = slides.filter((s) => !s.isImage);
  return (
    <div className="event-details-gallery  flex flex-col  duration-300 ease-in sm:w-full md:w-2/3">
      <div className=" flex items-center flex flex-col sm:flex-row   ">
        {videos.map((slide, index) => {
          const videosLength = videos.length;
          return (
            <div
              key={videos[index].url}
              className={`flex-1 mt-2 sm:mt-0 w-full   ${
                index !== slides.length - 1 ? 'sm:mr-2' : ''
              }`}
              style={{
                minWidth: `${videosLength > 1 ? 'calc(50% - 4px)' : '100%'}`,
                height: `${videosLength > 1 ? '220px' : '300px'}`,
              }}>
              <video
                key={videos[index].url}
                ref={videoFrames[index]}
                className={`max-w-full h-full `}>
                <track
                  src={videos[index].url}
                  autoPlay={false}
                  controls={true}
                  kind={'captions'}
                />
              </video>
            </div>
          );
        })}
      </div>
      <div
        className={`grid grid-cols-1 mt-2 ${
          !slides[2].isHidden ? 'sm:grid-cols-3' : 'sm:grid-cols-2'
        } gap-2`}>
        {!slides[0].isHidden && (
          <LazyImage
            className=" w-full  h-full sm:h-40 cursor-pointer  "
            src={slides[0].url}
            onClick={() => slider.showSlides(slider.slides[0].id)}
            loadedClass="lazy-img-loaded "
            placeholderClass="lazy-img-loading"
            errorClass="lazy-img-error"
          />
        )}
        {!slides[1].isHidden && (
          <LazyImage
            className={` w-full  h-full sm:h-40 cursor-pointer  `}
            src={slides[1].url}
            onClick={() => slider.showSlides(slider.slides[1].id)}
            loadedClass="lazy-img-loaded "
            placeholderClass="lazy-img-loading"
            errorClass="lazy-img-error"
          />
        )}
        {!slides[2].isHidden && (
          <LazyImage
            className=" w-full cursor-pointer  h-full sm:h-40 "
            src={slides[2].url}
            onClick={() => slider.showSlides(slides[2].id)}
            loadedClass="lazy-img-loaded "
            placeholderClass="lazy-img-loading"
            errorClass="lazy-img-error"
          />
        )}
      </div>
      {slider.slides.length && (
        <Slider
          isShow={slider.isSlideShow}
          slides={slider.slides}
          setSlideOnClick={slider.setCurrentSlide}
          close={() => slider.setIsSlideShow(false)}
          currentSlide={slider.currentSlide}
          slideToPrev={slider.slideToPrev}
          slideToNext={slider.slideToNext}
        />
      )}
    </div>
  );
});
