import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const formatTimestampByPattern = ({
  timestamp,
  pattern = 'YYYY-MM-DD',
}) => {
  if (!timestamp) return undefined;
  return dayjs(timestamp).format(pattern);
};

export function getDurationFromMilliSeconds(milli) {
  let minutes = Math.floor(milli / 60000);
  let hours = Math.round(minutes / 60);
  let days = Math.round(hours / 24);

  return (
    (days && { value: days, unit: 'days' }) ||
    (hours && { value: hours, unit: 'hours' }) || {
      value: minutes,
      unit: 'minutes',
    }
  );
}
export function getMillisecondsFromDaysCount(days) {
  return 1000 * 60 * 60 * 24 * days;
}
export function getDaysFromMillisecondsCount(ms) {
  return ms / 24 / 60 / 60 / 1000;
}

export function getMillisecondsFromHoursCount(hours) {
  return 1000 * 60 * 60 * hours;
}
export function getHoursFromMillisecondsCount(ms) {
  return ms / 60 / 60 / 1000;
}
