import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { IconArrowLeft } from '@app/assets';
import {
  ButtonFilled,
  ButtonIcon,
  ButtonTransparent,
  Input,
  InputCheckboxWithLabel,
  InputRadioButton,
  InputSelect,
} from '@component/ui';
import { defaultsDeep } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import MultipleCheckbox from '../components/MultipleCheckbox';
import { AccessPreloader } from '../components/Preloader';
import { models } from '../utils/data';
import { isAdmin, isCameraValues, isCorrect } from '../utils/utils';
import { useTranslation } from 'react-i18next';

const defaultValues = {
  model_name: '',
  verb: '',
  role: '',
  comment: '',
  add: false,
  view: false,
  change: false,
  remove: false,
  camera: '',
};

const EditPage = inject(({ store }) => ({
  onUpdate: store.access.updateItem,
  item: store.access.current,
  items: store.access.listTable,
  getById: store.access.getCurrentById,

  cameras: store.groupsInterceptions.ListTableCamera,
}))(
  observer((props) => {
    const { item, items, cameras, onUpdate, getById } = props;
    const { handleSubmit, control, getValues, watch, reset } = useForm({
      defaultValues,
    });
    const { t } = useTranslation(['inputs', 'buttons', 'title']);

    let history = useHistory();
    const [checkboxDisabled, setCheckboxDisabled] = useState(true);
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [flag, setFlag] = useState(false);
    const [node, setNode] = useState();

    const goBack = () => {
      history.goBack();
      setFlag(false);
    };

    let { accessId } = useParams();

    useEffect(() => {
      if (accessId) {
        getById(accessId);
      }
    }, [accessId]);

    useEffect(() => {
      if (item.id) {
        const values = defaultsDeep({ ...item }, { ...defaultValues });
        const actions = values['actions'];

        const forValue = values.for.map((i) => i);
        if (!!node) {
          const res = node.reduce((acc, value, index) => {
            if (!!forValue.find((i) => i === value.value)) {
              if (!!value.children) {
                value.children.map((i) => {
                  acc = [...acc, i.value];
                });
              }
              acc = [...acc, value.value];
            }
            const result = new Set(acc);
            return [...result];
          }, []);

          setChecked(res);
        }

        const resetValue = {
          model_name: values.model_name,
          role: actions.some((i) => i === 'admin') ? 'admin' : 'user',
          add: actions.some((i) => i === 'add'),
          view: actions.some((i) => i === 'view'),
          change: actions.some((i) => i === 'change'),
          remove: actions.some((i) => i === 'remove'),
          comment: values?.comment,
          verb: values.verb,
          what: !!values.what.length ? values.what.map((i) => i) : [],
        };
        if (resetValue.role === 'user') {
          setCheckboxDisabled(false);
        }
        reset(resetValue);
      }
      return () => {
        setFlag(true);
      };
    }, [item]);

    const onSubmit = async (values) => {
      const role = isAdmin(values);
      const flag = isCorrect(checked, role);
      const camValues = isCameraValues(values);

      const forValue = checked.reduce((acc, item) => {
        if (item.indexOf('_') !== -1) {
          acc = [...acc, ...item.split('_')];
        } else {
          acc = [...acc, item];
        }
        const res = new Set(acc);
        return [...res];
      }, []);

      if (flag) {
        const data = {
          id: accessId,
          actions: role,
          verb: values.verb,
          what: camValues,
          comment: values.comment,
          for: forValue,
          model_name: values.model_name,
        };
        try {
          const error = await onUpdate(data);
          if (!error) {
            goBack();
          }
        } catch (e) {
          console.error(e, 'ERROR');
        }
      }
    };

    const verbValue = [
      { value: 'cannot', label: 'Запретить' },
      { value: 'can', label: 'Разрешить' },
    ];

    useEffect(() => {
      if (getValues().role === 'user') {
        setCheckboxDisabled(false);
      } else {
        setCheckboxDisabled(true);
        reset({
          role: 'admin',
          add: false,
          view: false,
          change: false,
          remove: false,
        });
      }
    }, [watch().role]);

    const cameraValues = cameras.map((item) => {
      return {
        label: item.name,
        name: `camera ${item.id}`,
      };
    });

    const modelOptions = Object.entries(models).map(([value, label]) => ({
      value,
      label,
    }));

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="margin-auto edit-terminal w-full flex flex-col">
        <div className="flex items-center mb-9">
          <ButtonIcon
            callback={goBack}
            Icon={() => (
              <IconArrowLeft className={'text-secondary hover:text-accent'} />
            )}
          />
          <span className="ml-6 text-3xl text-primary dark:text-white leading-9">
            {t('editRule', { ns: 'title' })}
          </span>
        </div>

        <div className="flex flex-col w-full">
          <Controller
            render={({
              field: { onChange, ref, value },
              formState: { error },
            }) => {
              return (
                <InputSelect
                  options={modelOptions}
                  hasErrors={error}
                  textError={error?.message}
                  className={'mt-4'}
                  type={'text'}
                  labelText={t('whatRules')}
                  defaultValue={value}
                  callback={onChange}
                  inputRef={ref}
                />
              );
            }}
            name="model_name"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Обязательно для заполнения',
              },
            }}
          />

          {watch().model_name?.split(' ')[0] === 'Camera' && flag && (
            <div className="relative h-40">
              <div
                className={
                  'mt-3 mb-3 flex-column space-y-2 w-full h-40 overflow-y-scroll absolute'
                }>
                {cameraValues.map((item) => (
                  <Controller
                    render={({ field: { onChange, value } }) => {
                      const checked = getValues().what.some(
                        (i) => i === item.name.split(' ')[1],
                      );
                      return (
                        <InputCheckboxWithLabel
                          labelText={item.label}
                          callback={onChange}
                          checked={checked || null}
                        />
                      );
                    }}
                    name={item.name}
                    control={control}
                  />
                ))}
              </div>
            </div>
          )}

          <Controller
            render={({
              field: { onChange, ref, value },
              formState: { error },
            }) => {
              return (
                <InputSelect
                  options={verbValue}
                  hasErrors={error}
                  textError={error?.message}
                  className={'mt-4'}
                  type={'text'}
                  labelText={t('addGroup')}
                  defaultValue={value}
                  callback={onChange}
                  inputRef={ref}
                />
              );
            }}
            name="verb"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Обязательно для заполнения',
              },
            }}
          />

          <div className={'flex-column'}>
            <Controller
              render={({ field: { onChange, ref, value } }) => {
                return (
                  <InputRadioButton
                    id="admin"
                    className={'mt-4'}
                    labelText={`${t('admin', { ns: 'buttons' })}`}
                    callback={onChange}
                    inputRef={ref}
                    radioValue="admin"
                    defaultValue={value}
                  />
                );
              }}
              name="role"
              control={control}
            />
            <Controller
              render={({
                field: { onChange, ref, value },
                formState: { error },
              }) => {
                return (
                  <InputRadioButton
                    id="user"
                    groupName="type-list"
                    className={'mt-3'}
                    labelText={`${t('choose', { ns: 'buttons' })}`}
                    callback={onChange}
                    inputRef={ref}
                    radioValue="user"
                    defaultValue={value}
                  />
                );
              }}
              name="role"
              control={control}
            />
          </div>
          {flag ? (
            <div className={'mt-2 ml-8 flex-column space-y-2'}>
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputCheckboxWithLabel
                      labelText={`${t('add', { ns: 'buttons' })}`}
                      callback={onChange}
                      disabled={checkboxDisabled}
                      checked={value}
                    />
                  );
                }}
                name={'add'}
                control={control}
              />
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputCheckboxWithLabel
                      labelText={`${t('view', { ns: 'buttons' })}`}
                      callback={onChange}
                      disabled={checkboxDisabled}
                      checked={value}
                    />
                  );
                }}
                name={'view'}
                control={control}
              />
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputCheckboxWithLabel
                      labelText={`${t('change', { ns: 'buttons' })}`}
                      callback={onChange}
                      disabled={checkboxDisabled}
                      checked={value}
                    />
                  );
                }}
                name={'change'}
                control={control}
              />
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputCheckboxWithLabel
                      labelText={`${t('remove', { ns: 'buttons' })}`}
                      callback={onChange}
                      disabled={checkboxDisabled}
                      checked={value}
                    />
                  );
                }}
                name={'remove'}
                control={control}
              />
            </div>
          ) : null}

          <Controller
            render={({ field: { onChange, ref, value } }) => {
              return (
                <Input
                  className={'mt-6'}
                  type={'text'}
                  labelText={t('comment')}
                  defaultValue={value}
                  callback={onChange}
                  inputRef={ref}
                />
              );
            }}
            name="comment"
            control={control}
          />
          <AccessPreloader>
            <MultipleCheckbox
              checked={checked}
              expanded={expanded}
              setChecked={setChecked}
              setExpanded={setExpanded}
              setNode={setNode}
            />
          </AccessPreloader>
          <div className={'flex space-x-3 mt-8'}>
            <ButtonFilled text={t('save', { ns: 'buttons' })} submit />
            <ButtonTransparent
              callback={() => {
                goBack();
              }}
              text={`${t('reset', { ns: 'buttons' })}`}
            />
          </div>
        </div>
      </form>
    );
  }),
);

export default EditPage;
