import { PortalModal } from '@app/components';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef } from 'react';

export const ImageModal = inject(({ store }) => ({
  setShowDrawer: store.ui.setShowDrawer,
  isShow: store.ui.showDrawer.imageModal,
  imageModalInfo: store.eventsInterceptions.imageModalInfo,
}))(
  observer((props) => {
    const { setShowDrawer, isShow, imageModalInfo } = props;
    let modalWrapperRef = useRef(null);
    let closeIconRef = useRef(null);

    const onClose = () => {
      setShowDrawer('imageModal', false);
    };

    const closeModal = (event) => {
      const domNodeBar = modalWrapperRef.current;
      const domCloseIconNode = closeIconRef.current;
      if (event.target == domNodeBar) {
        return onClose();
      }
      if (
        event.target === domCloseIconNode ||
        domCloseIconNode?.contains(event.target)
      ) {
        onClose();
      }
      if (domNodeBar?.contains(event.target)) {
        return null;
      }
    };

    function closeOnEscKeyPressed(e) {
      if (
        e.keyCode === 27 &&
        isShow &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.altKey
      ) {
        e.stopPropagation();
        onClose();
      }
    }

    useEffect(() => {
      document.addEventListener('keydown', closeOnEscKeyPressed, true);
      return () => {
        document.removeEventListener('keydown', closeOnEscKeyPressed, true);
      };
    }, [isShow]);

    return (
      <PortalModal
        isShow={isShow}
        Element={() => {
          return (
            <div
              ref={modalWrapperRef}
              tabIndex={0}
              role={'button'}
              onClick={closeModal}
              className="modal-window fixed inset-0 z-[1002]">
              <div className={`modal-box bg-white`}>
                <img
                  className={
                    'cursor-default transition duration-150 max-h-[80vh] bg-bg min-w-[70vw] min-h-[70vh] m-1'
                  }
                  src={imageModalInfo.url}
                  alt={imageModalInfo.type}
                />
              </div>
            </div>
          );
        }}
      />
    );
  }),
);
