import React from 'react';

import { CallbackButton } from '../../HOC';

export const ButtonIcon = ({
  className,
  disabled,
  Icon,
  callback,
  submit,
  ...props
}) => {
  const _callbackButton = () => {
    if (!submit && callback) {
      callback();
    }
  };
  return (
    <CallbackButton
      {...props}
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      className={`flex transition-all duration-150 items-center ${
        className || ''
      } w-6 h-6    focus:outline-black dark:focus:outline-white`}
      callback={_callbackButton}>
      <Icon />
    </CallbackButton>
  );
};
