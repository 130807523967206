import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary caught an error:', error, errorInfo);
    this.setState({ errorInfo });
  }

  handleResetError = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null,
    });
  };

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { children, fallback } = this.props;

    if (hasError) {
      if (fallback) {
        return (
          <div>
            {fallback}
            <button
              onClick={this.handleResetError}
              style={{ marginTop: '1rem' }}>
              Повторить
            </button>
            <DetailsOfError error={error} errorInfo={errorInfo} />
          </div>
        );
      }

      // Дефолтный вариант, если fallback не передан
      return (
        <div style={{ padding: '1rem', border: '1px solid red' }}>
          <div className="flex gap-2">
            <h2>Что-то пошло не так...</h2>
            <button onClick={this.handleResetError}>Обновить страницу</button>
          </div>
          <DetailsOfError error={error} errorInfo={errorInfo} />
        </div>
      );
    }

    return children;
  }
}

function DetailsOfError({ error, errorInfo }) {
  if (!error) return null;

  return (
    <details
      className={'flex flex-col gap-2'}
      style={{ whiteSpace: 'pre-wrap', marginTop: '1rem' }}
      open>
      <summary>Детали ошибки</summary>
      <p>
        <strong>Сообщение:</strong> {error.message}
      </p>
      <p>
        <strong>Stack:</strong>
      </p>
      <code>{error.stack}</code>

      {errorInfo && errorInfo.componentStack && (
        <div>
          <p>
            <strong>React componentStack:</strong>
          </p>
          <code>{errorInfo.componentStack}</code>
        </div>
      )}
    </details>
  );
}

export default ErrorBoundary;
